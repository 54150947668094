<ng-container *transloco="let t; read:'changelog-update-item'">
  @if (update) {
    <div class="update-details">
      @if (update.blogPart) {
        <div class="blog-content" [innerHTML]="update.blogPart | safeHtml"></div>

        <div class="setting-section-break"></div>
      }

      <div class="mt-4">
        <app-update-section [items]="update.added" [title]="t('added')"></app-update-section>
        <app-update-section [items]="update.changed" [title]="t('changed')"></app-update-section>
        <app-update-section [items]="update.fixed" [title]="t('fixed')"></app-update-section>
        <app-update-section [items]="update.developer" [title]="t('developer')"></app-update-section>
        <app-update-section [items]="update.theme" [title]="t('theme')"></app-update-section>
        <app-update-section [items]="update.removed" [title]="t('removed')"></app-update-section>
        <app-update-section [items]="update.api" [title]="t('api')"></app-update-section>
        <app-update-section [items]="update.featureRequests" [title]="t('feature-requests')"></app-update-section>
        <app-update-section [items]="update.knownIssues" [title]="t('known-issues')"></app-update-section>
      </div>

      @if (showExtras) {
        <div class="setting-section-break"></div>

        <div class="mb-1 mt-2">
          <span class="text-muted">{{t('published-label')}}{{update.publishDate | date: 'short'}}</span>
          @if (!update.isDocker && (accountService.isAdmin$ | async)) {
            @if (update.updateVersion === update.currentVersion) {
              <a href="{{update.updateUrl}}" class="btn disabled btn-{{index === 0 ? 'primary' : 'secondary'}} float-end" target="_blank" rel="noopener noreferrer">{{t('installed')}}</a>
            } @else {
              <a href="{{update.updateUrl}}" class="btn btn-{{index === 0 ? 'primary' : 'secondary'}} float-end" target="_blank" rel="noopener noreferrer">{{t('download')}}</a>
            }
          }
        </div>
      }
    </div>
  }
</ng-container>

